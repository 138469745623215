<template>
  <div style="height: 100%">
    <horizontalMenu></horizontalMenu>
    <div class="container mt-3">
      <div class="pa-xxs-1">
        <v-progress-circular
          v-if="documentsLoading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-container v-if="specialOffer" class="mt-2 d-block d-lg-none">
          <router-link class="see-more-white" :to="'/documents/' + specialOffer.id + '/general'">
            <v-row class="orange-gradient">
              <v-col cols="2">
                <button class="offer-icon"></button>
              </v-col>
              <v-col cols="8">
                {{ $t('settlement_available', { year: specialOffer.year })}}
              </v-col>
              <v-col cols="2">
                <br>
                <br>
                <span class="see-more-white text-right">
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-arrow-right
                  </v-icon>
                </span>
              </v-col>
            </v-row>
          </router-link>
        </v-container>
        <v-container v-if="!documentsLoading">
          <div class="mb-4"
               :class="[
                 displayNeededInformation(document) ? 'needed-information' : 'card-dashboard-container',
                 document.attributes.finished_document ? 'finished-container' : null
               ]"
               v-for="(document) in documents"
               :key="document.id">
            <div class="row d-flex d-lg-none mt-0 pa-0">
              <v-col cols="9">
                <h4 class="year_h4">
                  <span>{{document.attributes['year'] }}</span>
                  <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
                </h4>
              </v-col>
              <v-col cols="3" class="text-right">
                <div class="symbol_div">{{document.attributes['symbol'] }}</div>
              </v-col>
            </div>
            <div class="row pa-0 pr-2 mt-0">
              <v-col cols="12" lg="5" class="document-status pt-0">
                <div v-if="document.attributes['status'] &&  document.attributes.current_step">
                  <div class="mb-4 semibold-font" :class="document.attributes.finished_document ? 'document-status' : 'gradient-pink'">{{ document.attributes['status'] }}</div>
                  <h5 class="document-current-step-description-small">{{ $t('settlement_stage_uppercase') }}:</h5>
                  <div class="document-current-step-description-small">{{ document.attributes.current_step['step_name'] }}</div>
                </div>
              </v-col>
            </div>
            <div class="see-more mt-6 text-right">
              <router-link class="see-more" :to="'/documents/' + document.id + '/general'">
                {{ displayNeededInformation(document) ? $t('fill_data') : $t('find_out_more') }}
                <v-icon
                  small
                  class="ml-2 arrow-icon"
                >
                  fas fa-arrow-right
                </v-icon>
              </router-link>
            </div>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HorizontalMenu from '../components/navigation/horizontalMenu.vue'

export default {
  name: 'Documents',
  computed: {
    ...mapGetters(['documents']),
    ...mapGetters(['documentsLoading']),
    ...mapGetters(['specialOffer'])
  },
  components: {
    HorizontalMenu
  },
  created () {
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  methods: {
    showDocument (documentId) {
      this.$router.push('/documents/' + documentId + '/general')
    },

    displayNeededInformation (document) {
      return document.attributes.client_can_edit === 'full'
    }
  }
}
</script>

<style lang="scss">
  .documents-table {
    background-color: transparent !important;
  }

  .finished-document {
    .year {
      background-color: #b4b4b4;
    }

    color: #b4b4b4;
  }

  .year {
    color: white !important;
    height: 48px;
    width: 48px;
    background-color: #D20F6E;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;

    span {
      margin-top: 15px;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .year-uk {
    span {
      margin-top: 5px;
    }
  }

  .show-document {
    cursor: pointer;
  }

  .product-name {
    @media only screen and (max-width: 1263px) {
      word-break: normal;
    }
  }

  .offer-icon {
    background: transparent url('./../assets/icons/exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }

  .see-more-white {
    color: white !important;
    text-decoration: none;

    a {
      color: white !important;
    }

    .arrow-icon {
      color: white !important;
    }
  }

  .see-more-white:hover {

    .arrow-icon {
      margin-right: 8px !important;
      margin-left: 0px !important;
    }
  }

  .see-more {
    color: #d2116e !important;
    text-decoration: none;
  }

  .arrow-icon {
    color: #d2116e !important;
  }

  .see-more:hover {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .arrow-icon:hover {
    margin-right: 8px !important;
    margin-left: 0px !important;
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .card-dashboard-container {
    max-width: 90vw;
    background-color: white;
    border-radius: 10px !important;
    opacity: 1;
    padding: 12px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20) !important;
  }

  .needed-information {
    max-width: 90vw;
    background-color: white;
    border-radius: 10px !important;
    padding: 12px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20) !important;
    border-radius: 10px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 1px solid transparent !important;
  }

  .finished-container {
    .year_h4, .product_h4, .document-status, .see-more, .arrow-icon {
      color: #b4b4b4 !important;
    }
  }
</style>

<template>
  <div style="height: 100%">
    <!-- <newDocument></newDocument> -->
    <horizontalMenu></horizontalMenu>
    <div class="container grey-background mt-3">
      <div class="pa-xxs-1">
        <v-progress-circular
          v-if="documentsLoading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h1 v-if="!documentsLoading && documents.length > 0" class="semibold-font">{{ $t('my_cases') }}</h1>
        <v-container v-if="specialOffer" class="mt-2 d-block d-lg-none">
          <router-link class="see-more-white" :to="'/documents/' + specialOffer.id + '/general'">
            <v-row class="orange-gradient">
              <v-col cols="2">
                <button class="offer-icon"></button>
              </v-col>
              <v-col cols="8">
                {{ $t('settlement_available', { year: specialOffer.year })}}
              </v-col>
              <v-col cols="2">
                <br>
                <br>
                <span class="see-more-white text-right">
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-arrow-right
                  </v-icon>
                </span>
              </v-col>
            </v-row>
          </router-link>
        </v-container>
        <v-simple-table v-if="!documentsLoading && documents.length > 0" class="mt-6 mb-12 documents-table d-block d-lg-none">
          <tbody>
            <tr @click="showDocument(document.id)" :class="document.attributes.finished_document ? 'finished-document' : null" v-for="(document) in documents" :key="document.id">
              <td width="5%" v-if="(document.attributes.year && !document.attributes.year.includes('/')) || !document.attributes.year" class="pb-4 pt-4">
                <div :class="document.counter % 3 === 0  ? 'yellow-color-square' : document.counter % 2 === 0  ? 'orange-color-square' : 'purple-color-square'" class="year row">
                  <span>{{ document.attributes.year }}</span>
                </div>
              </td>
              <td width="5%" v-if="document.attributes.year && document.attributes.year.includes('/')" class="pb-4 pt-4">
                <div :class="document.counter % 3 === 0  ? 'yellow-color-square' : document.counter % 2 === 0  ? 'orange-color-square' : 'purple-color-square'" class="year year-uk row">
                  <span>
                    {{ document.attributes.year.split('/')[0] }}<br>
                    {{ document.attributes.year.split('/')[1] }}
                  </span>
                </div>
              </td>
              <td class="pb-4 semibold-font">
                <br>
                <h3 class="product-name">{{ document.attributes.product }}</h3>
                {{ document.attributes.status }}
              </td>
              <td class="pb-4 text-left text-lg-right documents-symbol">
                {{ document.attributes.symbol }}
                <br>
                <br>
                <span class="see-more text-right">
                  <router-link class="see-more" :to="'/documents/' + document.id + '/general'">
                    <v-icon
                      small
                      class="ml-2 arrow-icon"
                    >
                      fas fa-arrow-right
                    </v-icon>
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <!-- PC -->
        <v-row v-if="specialOffer" class="mt-4 d-none d-lg-flex">
          <v-col cols="4">
            <img src='@/assets/clock.svg' />
          </v-col>
          <v-col cols="8">
            <div class="documents-table d-none d-lg-block orange-gradient">
              <button class="offer-icon ml-6 mr-4"></button>
              <span><strong>{{ $t('settlement_available', { year: specialOffer.year })}}</strong></span>
              <p v-if="specialOffer.product.includes('Niemcy')" class="ml-6 mt-8">{{ $t('settlement_available_info_de', { company: specialOffer.company })}}</p>
              <p v-if="specialOffer.product.includes('Holandia')" class="ml-6 mt-8">{{ $t('settlement_available_info_nl', { company: specialOffer.company })}}</p>

              <div class="see-more-white mt-6 text-right">
                <router-link class="see-more-white" :to="'/documents/' + specialOffer.id + '/general'">
                  {{ $t('more') }}
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-arrow-right
                  </v-icon>
                </router-link>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-simple-table v-if="!documentsLoading" class="mt-6 documents-table d-none d-lg-block">
          <thead>
            <tr>
              <th width="5%">{{ $t("year_uppercase") }}</th>
              <th>{{ $t("product_uppercase") }}</th>
              <th>{{ $t("case_number_uppercase") }}</th>
              <th>{{ $t("settlement_stage_uppercase") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr @click="showDocument(document.id)" :class="document.attributes.finished_document ? 'finished-document show-document' : 'show-document'" v-for="(document) in documents" :key="document.id">
              <td width="5%" v-if="(document.attributes.year && !document.attributes.year.includes('/')) || !document.attributes.year">
                <div :class="document.counter % 3 === 0  ? 'yellow-color-square' : document.counter % 2 === 0  ? 'orange-color-square' : 'purple-color-square'" class="year row">
                  <span>{{ document.attributes.year }}</span>
                </div>
              </td>
              <td width="5%" v-if="document.attributes.year && document.attributes.year.includes('/')">
                <div :class="document.counter % 3 === 0  ? 'yellow-color-square' : document.counter % 2 === 0  ? 'orange-color-square' : 'purple-color-square'" class="year year-uk row">
                  <span>
                    {{ document.attributes.year.split('/')[0] }}<br>
                    {{ document.attributes.year.split('/')[1] }}
                  </span>
                </div>
              </td>
              <td><strong>{{ document.attributes.product }}</strong></td>
              <td>{{ document.attributes.symbol }}</td>
              <td><strong>{{ document.attributes.status }}</strong></td>
              <td class="see-more">
                <router-link class="see-more" :to="'/documents/' + document.id + '/general'">
                  {{ $t('see') }}
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-arrow-right
                  </v-icon>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import newDocument from '@/components/banners/newDocument.vue'
import HorizontalMenu from '../components/navigation/horizontalMenu.vue'

export default {
  name: 'Documents',
  computed: {
    ...mapGetters(['documents']),
    ...mapGetters(['documentsLoading']),
    ...mapGetters(['specialOffer'])
  },
  components: {
    // newDocument,
    HorizontalMenu
  },
  created () {
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  methods: {
    showDocument (documentId) {
      this.$router.push('/documents/' + documentId + '/general')
    }
  }
}
</script>

<style lang="scss">
  .documents-table {
    background-color: transparent !important;
  }

  .finished-document {
    .year {
      background-color: #b4b4b4;
    }

    color: #b4b4b4;
  }

  .year {
    color: white !important;
    height: 48px;
    width: 48px;
    background-color: #D20F6E;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;

    span {
      margin-top: 15px;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .year-uk {
    span {
      margin-top: 5px;
    }
  }

  .show-document {
    cursor: pointer;
  }

  .product-name {
    @media only screen and (max-width: 1263px) {
      word-break: normal;
    }
  }

  .offer-icon {
    background: transparent url('./../assets/icons/exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }

  .see-more-white {
    color: white !important;
    text-decoration: none;

    a {
      color: white !important;
    }

    .arrow-icon {
      color: white !important;
    }
  }

  .see-more-white:hover {

    .arrow-icon {
      margin-right: 8px !important;
      margin-left: 0px !important;
    }
  }
</style>

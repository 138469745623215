<template>
  <div style="height: 100%">
    <div>
      <div class="pa-xxs-1">
        <!-- Use Bootstrap classes for responsive design -->
        <div class="d-block d-lg-none">
          <DocumentsMobile :documents="documents" :documentsLoading="documentsLoading" />
        </div>
        <div class="d-none d-lg-block">
          <DocumentsDesktop :documents="documents" :documentsLoading="documentsLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentsMobile from './DocumentsMobile.vue'
import DocumentsDesktop from './DocumentsDesktop.vue'

export default {
  name: 'Documents',
  components: {
    DocumentsMobile,
    DocumentsDesktop
  },
  computed: {
    ...mapGetters(['documents', 'documentsLoading', 'specialOffer'])
  },
  created () {
    this.$store.dispatch('getDocuments')
    this.$store.dispatch('getInfo')
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
